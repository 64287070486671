import * as cookieconsent from 'vanilla-cookieconsent';
import '../../node_modules/vanilla-cookieconsent/dist/cookieconsent.css';

var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
    gui_options: {
        consent_modal: {
            layout: 'bar',               // box/cloud/bar
            position: 'top center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            position: 'left',              // left/right
            transition: 'slide'            // zoom/slide
        }
    },

    current_lang: 'en',
    //autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    force_consent: true,                   // default: false
    hide_from_bots: true,                   // default: true
    remove_cookie_tables: true,          // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function (user_preferences, cookie) {
        // callback triggered only once
    },

    onAccept: function (cookie) {
        if (cc.allowedCategory('analytics')) {
            gtag('consent', 'update', {
                'analytics_storage': 'granted'
            });

            posthog.init(POSTHOG_API_KEY,{api_host:'https://eu.posthog.com'})
        } else {
            posthog.init(POSTHOG_API_KEY,{api_host:'https://eu.posthog.com', persistence: 'memory'})
        }
    },

    onChange: function (cookie, changed_preferences) {
        var toggleAnalytics = function(enabled) {
            gtag('consent', 'update', {
                'analytics_storage': enabled == true ? 'granted' : 'denied'
            });

            if (enabled) {
                posthog.set_config({persistence: 'cookie'});
            } else {
                posthog.set_config({persistence: 'memory'});
            }
        }

        changed_preferences.forEach(function(pref) {
            switch(pref) {
                case 'analytics':
                    toggleAnalytics(cc.allowedCategory('analytics'));
                    break;
            }
        });
    },

    languages: {
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: 'Hi, this website uses essential cookies to ensure its proper operation as well as tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Cookie usage',
                        description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/privacy-policy" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Performance and Analytics cookies',
                        description: 'These cookies allow the website to remember the choices you have made in the past in order to understand how it is being used and how it can be made better in the future',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: true,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 years',
                                col4: 'description ...',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 day',
                                col4: 'description ...',
                            },
                            {
                                col1: '^ph_',
                                col2: 'posthog.com',
                                col3: '1 year',
                                col4: 'description...',
                                is_regex: true
                            }
                        ]
                    }, {
                        title: 'More information',
                        description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="mailto:help@assetroom.net">contact us</a>.',
                    }
                ]
            }
        }
    }
});